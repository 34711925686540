import type Tag from '~/entities/tag'
import type TagParent from '~/entities/tagParent'
import type TagTypeKey from '~/types/tagTypeKey'

export const availableImportantCountry = [
  'france',
  'united-kingdom',
  'united-states',
  'belgium',
] as const
export type ImportantCountry = (typeof availableImportantCountry)[number]

/**
 * This is a helper to sort tag arrays based on their current translated value.
 *
 * @param typeName - If you want to use country it will work but you should rather use the countryTagCompare fn.
 * @param a - A element.
 * @param b - B element.
 * @param isParent - Here to determine the i18n prefix.
 * @returns 0 | 1 | -1.
 */
export function useSortTagsByTranslatedName() {
  const { t } = useI18n()

  return function <T extends Tag | TagParent>(
    typeName: TagTypeKey,
    a: Omit<T, 'toJSON'>,
    b: Omit<T, 'toJSON'>,
    isParent = false,
  ): number {
    const subTypeKey = isParent ? 'parentTags' : 'tags'
    return t(`${subTypeKey}.${typeName}.${a.name}`).localeCompare(
      t(`${subTypeKey}.${typeName}.${b.name}`),
    )
  }
}

export function useSortTagsByCountry() {
  const { t } = useI18n()

  return {
    sortTagsByCountry: function <T extends Tag | TagParent>(
      a: Omit<T, 'toJSON'>,
      b: Omit<T, 'toJSON'>,
    ): number {
      const subTypeKey = 'tags'
      // @ts-expect-error this should work in ts 5.5
      const aIsImportant = availableImportantCountry.includes(a.name)
      // @ts-expect-error this should work in ts 5.5
      const bIsImportant = availableImportantCountry.includes(b.name)
      if (aIsImportant && bIsImportant) {
        return 0
      } else if (aIsImportant && !bIsImportant) {
        return -1
      } else if (!aIsImportant && bIsImportant) {
        return 1
      } else {
        return t(`${subTypeKey}.country.${a.name}`).localeCompare(
          t(`${subTypeKey}.country.${b.name}`),
        )
      }
    },
  } as const
}

export function useGetTranslatedTagEntityName() {
  const { t } = useI18n()

  return function (
    entity: Tag | TagParent,
    typeName: TagTypeKey | 'nationalities',
    amount = 1,
  ): string {
    const isParent = entity.className === 'TagParent'

    if (!isParent) {
      return t(`tags.${typeName}.${entity.name}`, amount)
    } else {
      return t(`parentTags.${typeName}.${entity.name}`, amount)
    }
  }
}
